import React from "react";

// COMPONENTS
import Profile from "./Profile";

function ProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}

export default ProfilePage;
